
<script>
export default {
  activated() {
    let redirectView = this.$route.fullPath.split("/redirect")[1]

    // const { path, query } = redirectView

    this.$router.replace(redirectView)
  },
  render: function (h) {
    return h() // avoid warning message
  },
}
</script>
